import React, { useState } from 'react';

const PianoBottomBanner = () => {
  const [isShowing, setIsShowing] = useState(true);

  const closeBanner = () => {
    setIsShowing(false);
  };

  if (!isShowing) return null;

  return (
    <div className="bottomBanner-wrapper">
      <div id="pianoBottomBanner" />
      <button
        className="close-btn"
        onClick={closeBanner}
        aria-label="Close Button"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path
            d="M12 12.8149L17.1851 18L18 17.1851L12.8149 12L18 6.81489L17.1851 6L12 11.1851L6.81489 6L6 6.81489L11.1851 12L6 17.1851L6.81489 18L12 12.8149Z"
            fill="#000000"
          />
        </svg>
      </button>
    </div>
  );
};

export default PianoBottomBanner;
